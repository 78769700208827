import { TitleActionBar } from "../../components";
import "./DrawingToolForIngredient.scss";
import CanvasMainForIngredientTool from "./CanvasMainForIngredientTool";
import AddingItemsPanel from "./AddingNodes/AddingItemsPanel";
import {
  ReactFlowProvider,
  Node,
  Edge,
  useEdgesState,
  EdgeChange,
  useNodesState,
  NodeChange,
} from "reactflow";
import {
  Dispatch,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import UpdateSupplierNodeModal from "./UpdateSupplierNodeModal";
import {
  Sidebar,
  Segment,
  Button,
  Icon,
  Dimmer,
  Loader,
  Popup,
} from "semantic-ui-react";
import {
  useGetIngredientForSupplier,
  useGetSupplierDrawingToolData,
  useCreateSupplierDrawing,
  useUpdateSupplierDrawing,
} from "../../api/supplierDrawingTool";
import { AuthContext } from "../../context/AuthContext";
import {
  useCompleteDrawingRequest,
  useDrawingRequestUpdate,
} from "../../api/notification";
import { SupplierRequestStatus } from "../../config/enums/SupplierRequestStatus.enum";

interface SupplierDrawingsState {
  nodeItemId: any | null;
  supplierIngredientId: any;
  supplierId: any;
  chartNodes: Node<any>[];
  chartEdges: Edge<any>[];
  setChartNodes: Dispatch<Node[]>;
  setChartEdges: Dispatch<Edge[]>;
  onNodesChange: Dispatch<NodeChange[]>;
  onEdgesChange: Dispatch<EdgeChange[]>;
  setNodeItem: Dispatch<any>;
  updateChartGroup: (groupClassName: string, node: Node) => void;
  saveDrawing: (chartEdges: any, chartNodes: any) => void;
}

const initialState: SupplierDrawingsState = {
  nodeItemId: null,
  supplierIngredientId: undefined,
  supplierId: undefined,
  chartNodes: [],
  chartEdges: [],
  setChartNodes: () => {},
  setChartEdges: () => {},
  onNodesChange: () => {},
  onEdgesChange: () => {},
  setNodeItem: () => {},
  updateChartGroup: () => {},
  saveDrawing: () => {},
};

export const DrawingForIngredientContext = createContext(initialState);

const DrawingToolForIngredient = () => {
  const authState = useContext(AuthContext);
  const supplierUser = authState?.user || { _id: null };
  // Drawing States
  const [chartNodes, setChartNodes, onNodesChange] = useNodesState([]);
  const [chartEdges, setChartEdges, onEdgesChange] = useEdgesState([]);
  const [nodeItemId, setNodeItem] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  // Dropdown States
  const [searchKey, setSearchKey] = useState("");
  const [supplierIngredientId, setSupplierIngredientId] = useState<string>();

  const { data: ingredientData, isLoading: isIngredientLoading } =
    useGetIngredientForSupplier({
      searchKey,
    });

  const { mutate: drawingCompleted } = useCompleteDrawingRequest();
  const { mutate: drawingRequestUpdate } = useDrawingRequestUpdate();

  const supplierId = useMemo(() => {
    return ingredientData?.find(
      (ingredient) => ingredient._id === supplierIngredientId
    )?.supplierId;
  }, [supplierIngredientId, ingredientData]);

  const {
    data: drawingToolData,
    isLoading: isDrawingToolLoading,
    isSuccess,
    isError,
    refetch: reFetchDrawingTool,
  } = useGetSupplierDrawingToolData(supplierIngredientId, supplierId);

  const { mutate: createDrawing, isLoading: isLoadingCreateDrawing } =
    useCreateSupplierDrawing();
  const { mutate: updateDrawing, isLoading: isLoadingUpdateDrawing } =
    useUpdateSupplierDrawing();

  useEffect(() => {
    if (isSuccess || isError) {
      setChartNodes(drawingToolData?.chartNodes || []);
      setChartEdges(drawingToolData?.chartEdges || []);
    }
  }, [drawingToolData, isError, isSuccess]);

  const updateDrawingRequest = (status: number) => {
    drawingRequestUpdate({ ingredientId: supplierIngredientId, status });
  };

  const saveDrawing = useCallback(
    (chartEdges: any, chartNodes: any) => {
      if (drawingToolData?._id) {
        updateDrawing(
          {
            ...drawingToolData,
            chartEdges,
            chartNodes,
          },
          {
            onSuccess: () => {
              updateDrawingRequest(SupplierRequestStatus.Updated);
            },
          }
        );
        return;
      }
      const newDrawing = {
        chartNodes,
        chartEdges,
        supplierId: supplierId,
        ingredientId: supplierIngredientId,
      };

      createDrawing(newDrawing, {
        onSuccess: () => {
          reFetchDrawingTool();
          updateDrawingRequest(SupplierRequestStatus.InProgress);
        },
      });
    },
    [drawingToolData, supplierIngredientId, updateDrawing, createDrawing]
  );

  // Data for dropdown
  const ingredients: DropdownOption[] = (ingredientData || []).map(
    ({ _id, ingredient_name }) => ({
      key: _id,
      text: ingredient_name,
      value: _id,
    })
  );

  const updateChartGroup = useCallback(
    (groupClassName: string, node: Node) => {
      setChartNodes((nds) => {
        const data = [...nds];
        return data.map((n) => {
          if (n.type === "farmGate" || n.type === "processGate") {
            return {
              ...n,
              className: groupClassName,
            };
          } else if (n.id === node.id) {
            return {
              ...n,
              position: node.position,
            };
          }
          return { ...n };
        });
      });
    },
    [setChartNodes]
  );

  // Context Provider Values
  const value = {
    chartNodes,
    chartEdges,
    nodeItemId,
    supplierIngredientId,
    supplierId,
    setChartNodes,
    setChartEdges,
    onEdgesChange,
    onNodesChange,
    setNodeItem,
    updateChartGroup,
    saveDrawing,
  };

  if (!supplierIngredientId) {
    return (
      <>
        <TitleActionBar
          title={"Ingredient Name"}
          currentData={ingredients}
          defaultValue={supplierIngredientId}
          handleChangeState={(e: any, data: any) => {
            setSupplierIngredientId(data.value as string);
          }}
          onSearchChange={(e: any, data: any) => {
            setSearchKey(data.searchQuery);
          }}
          loading={isIngredientLoading}
          search
        />
        <div className="product-empty-description">
          <h3>
            Please select the product or create the product if product is not
            available from the dropdown.
          </h3>
        </div>
      </>
    );
  }

  // Loading state check
  if (
    isDrawingToolLoading ||
    isLoadingCreateDrawing ||
    isLoadingUpdateDrawing
  ) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <DrawingForIngredientContext.Provider value={value}>
      <ReactFlowProvider>
        <Sidebar.Pushable as={Segment} style={{ overflow: "hidden" }}>
          <AddingItemsPanel
            isVisible={isVisible}
            onClose={() => setIsVisible(!isVisible)}
          />
          <TitleActionBar
            title={"Product Name"}
            currentData={ingredients}
            defaultValue={supplierIngredientId}
            handleChangeState={(e: any, data: any) => {
              setSupplierIngredientId(data.value as string);
            }}
            onSearchChange={(e: any, data: any) => {
              setSearchKey(data.searchQuery);
            }}
            loading={isIngredientLoading}
            search
          />
          <Button
            icon
            color="teal"
            className="open-side-menu-button"
            onClick={() => {
              setIsVisible(!isVisible);
            }}
          >
            <Icon name="angle double right" />
          </Button>
          <CanvasMainForIngredientTool />
          <Popup
            content="Save Drawing."
            size="mini"
            trigger={
              <Button
                icon
                color="teal"
                className="drawing-save-button"
                onClick={() => {
                  saveDrawing(chartEdges, chartNodes);
                }}
              >
                Save
              </Button>
            }
          />
          <Popup
            content="Complete Ingredient Drawing."
            size="mini"
            trigger={
              <Button
                icon
                color="teal"
                className="drawing-complete-button"
                onClick={() => {
                  updateDrawingRequest(SupplierRequestStatus.Completed);
                }}
              >
                Completed
              </Button>
            }
          />
          {!!nodeItemId ? <UpdateSupplierNodeModal /> : null}
        </Sidebar.Pushable>
      </ReactFlowProvider>
    </DrawingForIngredientContext.Provider>
  );
};

export default DrawingToolForIngredient;
