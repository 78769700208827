import React, {
  forwardRef,
  useCallback,
  useEffect,
  useState,
  useImperativeHandle,
  useMemo,
} from "react";
import { useForm } from "react-hook-form";
import isEmpty from "lodash/isEmpty";

import {
  usePutIngredientData,
  usePostSupplierData,
  usePutSupplierData,
} from "../../../api/ingredients";
import { errorView, successMessage } from "../../../helpers/ErrorHandler";
import {
  useGetCountries,
  useGetProduceTypes,
  useGetSpecifyIngredient,
} from "../../../api/static-data";
import SupplierRegisterForm from "../../supplier/supplierRegisterForm/SuppllierRegisterForm";
import SupplerIngredientDataView from "./SupplierIngredientDataView";

import "./IngredientRegister.scss";
import { useCreateSupplierDrawingRequest } from "../../../api/supplier";
import { get } from "lodash";

interface HandleValidation {
  resetSupplierData(): void;
}

const SupplerRegister = forwardRef((props: any, ref) => {
  const {
    ingredientSupplierList = [],
    supplierCurrentData,
    ingredientData,
    refetchSupplier = () => {},
    setIngredientData = () => {},
    setSupplier = () => {},
    loadPackageData = () => {},
    hideIngredientView = () => {},
    disableEdit = false,
    dataView = false,
    changeDataIngredient = () => {},
  } = props;

  const [newSupplier, setNewSupplier] = useState(false);
  const [supplierData, setSupplierData] = useState<any>({});
  const [ingredientType, setIngredientType] = useState<any>("RAW");
  const [defaultType, setDefaultType] = useState<any>("ml");
  const [country, setCountry] = useState<any>("Denmark");
  const [dataType, setDataType] = useState<any>("SUPPLIER");
  const [isSupplierAsUser, setIsSupplierAsUser] = useState<any>(false);
  const [produceType, setProduceType] = useState<any>("");
  const [farmingType, setFarmingType] = useState<string>("Unknown");
  const [fieldType, setFieldType] = useState<string>("Unknown");
  const [specifyIngredientValue, setSpecifyIngredientValue] =
    useState<string>("");
  const [isSupplierExist, setIsSupplierExist] = useState<boolean>(false);

  useEffect(() => {
    if (supplierData?.supplier_data?._id) {
      const isSupplierExist = ingredientData?.ingredient_data?.suppliers?.find(
        (supplier: any) => supplier?._id === supplierData?.supplier_data?._id
      );

      setIsSupplierExist(!!isSupplierExist);
    }
  }, [supplierData, ingredientData?.ingredient_data?.suppliers]);

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const {
    register: registerNewSupplier,
    reset: resetNewSupplier,
    handleSubmit: handleSubmitNewSupplier,
    formState: { errors: errorsNewSupplier },
  } = useForm();

  const {
    data: produceTypesData,
    isSuccess: isProduceTypesSuccess,
    isLoading: isProduceTypesLoading,
  } = useGetProduceTypes();

  const closeSupplierData = () => {
    setNewSupplier(false);
    setIsSupplierAsUser(false);
    hideIngredientView(false);
    resetNewSupplier();
  };

  useImperativeHandle(ref, () => ({
    resetSupplierData() {
      closeSupplierData();
    },
  }));

  const {
    data: countriesData,
    isSuccess: isCountriesDataSuccess,
    isLoading: isCountriesDataLoading,
  } = useGetCountries();

  const countries = () => {
    if (isCountriesDataSuccess) {
      return countriesData?.map((c: any) => ({
        key: c.id,
        text: c.country,
        value: c.id.toString(),
      }));
    }
  };

  const { data: specifyIngredient } = useGetSpecifyIngredient();

  const produceTypes = useMemo(() => {
    if (isProduceTypesSuccess) {
      return produceTypesData?.map((p: any) => ({
        text: p.produce_name,
        key: p.id,
        value: p.id,
      }));
    }
  }, [produceTypesData, isProduceTypesSuccess]);

  const { mutateAsync: mutatePostSupplier } = usePostSupplierData();
  const { mutateAsync: mutatePutSupplier } = usePutSupplierData();
  const { mutateAsync: mutatePutIngredient } = usePutIngredientData();
  const { mutate: mutatePostSupplierDrawingRequest } =
    useCreateSupplierDrawingRequest();

  let ingredient_id =
    ingredientData?.ingredient_data?.ingredient_id ||
    ingredientData?.ingredient_data?._id ||
    ingredientData?.ingredient_id;

  const resetSupplierData = () => {
    reset();
    setIngredientType("RAW");
    setDefaultType("ml");
    setCountry(countries()?.[0]?.value || "Denmark");
    setDataType("SUPPLIER");
    loadPackageData(false);
    setProduceType("");
    setFarmingType("Unknown");
    setFieldType("Unknown");
    setSpecifyIngredientValue("");
  };

  const loadSupplierEditData = useCallback(() => {
    if (disableEdit) {
      setValue("supplier_name", ingredientData?.supplier_name || "");
      saveSupplierEditData();
    }
  }, [ingredientData, disableEdit]);

  useEffect(() => {
    resetSupplierData();
    loadSupplierEditData();
  }, [ingredientData, loadSupplierEditData]);

  const saveSupplierEditData = () => {
    let supplierIngredientData = ingredientData?.supplierIngredientDetails;
    saveValues(ingredientData, supplierIngredientData);
  };

  //save values
  const saveValues = (data: any, getSupplierIngredientDetails: any) => {
    let supplierTitle = data?.result?.title || data?.supplier_name;
    let supplierDetails = data?.result?.other?.supplierDetails || data;
    let supplierDataType =
      getSupplierIngredientDetails?.data_type || "SUPPLIER";
    let supplierData = {
      supplier_name: supplierTitle,
      supplier_data: { ...supplierDetails, dataType: supplierDataType },
    };
    setProduceType(getSupplierIngredientDetails?.produceType || "");
    setFarmingType(getSupplierIngredientDetails?.farmingType || "");
    setFieldType(getSupplierIngredientDetails?.fieldType || "");
    setSpecifyIngredientValue(
      getSupplierIngredientDetails?.specifyIngredient || ""
    );
    supplierCurrentData(supplierData);
    setSupplierData(supplierData);
    setIngredientType(getSupplierIngredientDetails?.ingredient_type || "RAW");
    setDefaultType(getSupplierIngredientDetails?.unit_type || "ml");
    setCountry(getSupplierIngredientDetails?.countryOrigin || "Denmark");
    setDataType(getSupplierIngredientDetails?.data_type || setUserDataType);
    setValue("unit_size", getSupplierIngredientDetails?.unit_size || 0);
    setValue("ean_bar_code", getSupplierIngredientDetails?.ean_bar_code || 0);
    setValue(
      "produceCultivar",
      getSupplierIngredientDetails?.produceCultivar || 0
    );
    setSupplier(supplierData);
    loadPackageData(true);
  };

  let supplierAsUser = useMemo(() => {
    const supplierAsUser =
      ingredientData?.supplierAsUser ||
      supplierData?.supplier_data?.is_supplier_as_user ||
      false;
    return supplierAsUser;
  }, [ingredientData, supplierData]);

  const setUserDataType = useMemo(() => {
    if (supplierAsUser) {
      const dataType = supplierData?.supplier_data?.dataType || "SUPPLIER";
      setDataType(dataType);
      return dataType;
    } else {
      const dataType = supplierData?.supplier_data?.dataType || "USER";
      setDataType(dataType);
      return dataType;
    }
  }, [supplierData, supplierAsUser]);

  // get supplier search data
  const getSearchValue = async (data: any) => {
    let supplierDetails = data?.result?.other?.supplierDetails || null;
    let supplierTitle = data?.result?.title || "";

    if (supplierTitle === "New") {
      setNewSupplier(true);
      hideIngredientView(true);
      supplierCurrentData(undefined);
      setSupplierData({});
    } else {
      let checkNowItsAvailable = supplierDetails?.ingredientIds.some(
        (e: any) => e == ingredient_id
      );

      if (checkNowItsAvailable) {
        let getSupplierIngredientDetails =
          supplierDetails.supplierIngredientDetails.find(
            (e: any) => e.ingredient_id == ingredient_id
          );
        setNewSupplier(false);
        saveValues(data, getSupplierIngredientDetails);
      } else {
        let supplierData = {
          supplier_name: supplierTitle,
          supplier_data: supplierDetails,
        };
        resetSupplierData();
        supplierCurrentData(supplierData);
        setSupplierData(supplierData);
      }
    }
  };

  // get supplier and ingredient ids
  const getIngredientAndSupplierId = (supplierData: any, ingredientId: any) => {
    let supplierProductId =
      supplierData?.supProductIds ||
      supplierData?.productIds ||
      supplierData?.supplierProductIds;
    let supplierIngredientData =
      supplierData?.supIngredientIds ||
      supplierData?.ingredientIds ||
      supplierData?.supplierIngredientIds;

    const newSupplierProductIds = supplierProductId;
    const newSupplierIngIds = supplierIngredientData?.includes(ingredientId)
      ? supplierIngredientData
      : [...supplierIngredientData, ingredientId];

    return {
      newSupplierProductIds,
      newSupplierIngIds,
    };
  };

  // create new supplier drawing request
  const handleSupplierDrawingRequestSave = (
    ingredientId: string,
    supplierUserId: string
  ) => {
    mutatePostSupplierDrawingRequest({
      ingredientId,
      supplierUserId,
    });
  };

  // update supplier
  const saveData = (data: any) => {
    if (dataType === "GENERIC") {
      if (ingredientType === "PROCESSED" && isEmpty(specifyIngredientValue)) {
        errorView("Please Specify Ingredient");
        return;
      }
      if (ingredientType === "RAW" && isEmpty(produceType)) {
        errorView("Please select Produce Type");
        return;
      }
    }

    const { newSupplierProductIds, newSupplierIngIds } =
      getIngredientAndSupplierId(supplierData?.supplier_data, ingredient_id);

    let supplierId =
      supplierData?.supplier_data?.supplier_id ||
      supplierData?.supplier_data?._id ||
      supplierData?.supplier_id;

    let supplierIngredientDetails = {
      ingredient_id: ingredient_id,
      data_type: dataType,
      unit_type: defaultType,
      ingredient_type: ingredientType,
      unit_size: data.unit_size,
      ean_bar_code: data.ean_bar_code,
      countryOrigin: country,
      specifyIngredient: specifyIngredientValue,
      produceType: produceType,
      produceCultivar: data.produceCultivar,
      farmingType: farmingType,
      fieldType: fieldType,
    };

    let supplierDataUpdate = {
      _id: supplierId,
      supplier_name: supplierData?.supplier_name,
      productIds: newSupplierProductIds ? newSupplierProductIds : [],
      ingredientIds: newSupplierIngIds ? newSupplierIngIds : [],
      supplierIngredientDetails: supplierIngredientDetails,
    };

    const updateIngredientData = {
      _id: ingredient_id,
      ingredient_name:
        ingredientData?.ingredient_data?.ingredient_name ||
        ingredientData?.ingredient_name,
      productIds:
        ingredientData?.ingredient_data?.productIds ||
        ingredientData?.ingredientProductIds,
    };

    mutatePutIngredient(updateIngredientData, {
      onSuccess(data: any) {
        successMessage("Ingredient update successfully");
        let ingredientData = {
          ingredient_name: data?.ingredient_name,
          ingredient_data: data,
        };
        setIngredientData(ingredientData);
        mutatePutSupplier(supplierDataUpdate, {
          onSuccess(data: any) {
            let supplierIngredient = data.supplierIngredientDetails;
            successMessage("Supplier update successfully");
            saveValues(data, supplierIngredient);
            refetchSupplier();
            if (data.is_supplier_as_user && dataType === "SUPPLIER") {
              const supplierUserId = data.user_id;
              handleSupplierDrawingRequestSave(ingredient_id, supplierUserId);
            }
          },
        });
      },
    });
  };

  const handleSearchValidation = (status: any) => {
    if (status) {
      resetSupplierData();
    }
  };

  //create new supplier
  const handleSupplierSave = (data: any) => {
    if (dataType === "GENERIC") {
      if (ingredientType === "PROCESSED" && isEmpty(specifyIngredientValue)) {
        errorView("Please Specify Ingredient");
        return;
      }
      if (ingredientType === "RAW" && isEmpty(produceType)) {
        errorView("Please select Produce Type");
        return;
      }
    }

    const dataToSubmit = {
      ...data,
      is_supplier_as_user: isSupplierAsUser,
      productIds: [],
      ingredientIds: [ingredient_id],
    };
    mutatePostSupplier(dataToSubmit, {
      onSuccess(data: any) {
        let supplierData = {
          supplier_name: data.supplier_name,
          supplier_data: data,
        };
        resetNewSupplier();
        supplierCurrentData(supplierData);
        setSupplierData(supplierData);
        successMessage("Supplier saved successfully");
        setNewSupplier(false);
        refetchSupplier();
        hideIngredientView(false);
        setIsSupplierAsUser(false);
        if (data.is_supplier_as_user && dataType === "SUPPLIER") {
          const supplierUserId = data.user_id;
          handleSupplierDrawingRequestSave(ingredient_id, supplierUserId);
        }
      },
    });
  };

  const checkRawOrProcess = useMemo(() => {
    if (ingredientType == "PROCESSED" && dataType == "GENERIC") {
      let data = {
        processIngredient: true,
        produceIngredient: false,
        enableIngredient: false,
      };
      changeDataIngredient(data);
      return data;
    } else if (ingredientType == "RAW" && dataType == "GENERIC") {
      let data = {
        processIngredient: false,
        produceIngredient: true,
        enableIngredient: true,
      };
      changeDataIngredient(data);
      return data;
    } else {
      let data = {
        processIngredient: false,
        produceIngredient: false,
        enableIngredient: false,
      };
      changeDataIngredient(data);
      return data;
    }
  }, [ingredientType, dataType]);

  return (
    <>
      {newSupplier ? (
        <SupplierRegisterForm
          handleSubmit={handleSubmitNewSupplier}
          onSubmit={handleSupplierSave}
          register={registerNewSupplier}
          errors={errorsNewSupplier}
          closeView={() => {
            hideIngredientView(false);
            setIsSupplierAsUser(false);
            setNewSupplier(false);
          }}
          updateSupplier={false}
          setIsSupplierAsUser={setIsSupplierAsUser}
          isSupplierAsUser={isSupplierAsUser}
        />
      ) : (
        <SupplerIngredientDataView
          dataView={dataView}
          supplierAsUser={supplierAsUser}
          handleSubmit={() => handleSubmit(saveData)}
          register={register}
          ingredientType={ingredientType}
          setIngredientType={setIngredientType}
          dataType={dataType}
          errors={errors}
          country={country}
          countries={() => countries()}
          setCountry={setCountry}
          isCountriesDataLoading={isCountriesDataLoading}
          defaultType={defaultType}
          setDefaultType={setDefaultType}
          setDataType={setDataType}
          produceType={produceType}
          produceTypes={produceTypes}
          setProduceType={setProduceType}
          isProduceTypesLoading={isProduceTypesLoading}
          farmingType={farmingType}
          fieldType={fieldType}
          setFarmingType={setFarmingType}
          setFieldType={setFieldType}
          disableEdit={disableEdit}
          errorsNewSupplier={errorsNewSupplier}
          ingredientSupplierList={ingredientSupplierList}
          supplierData={supplierData}
          handleSearchValidation={handleSearchValidation}
          getSearchValue={getSearchValue}
          checkRawOrProcess={checkRawOrProcess}
          specifyIngredient={specifyIngredient}
          specifyIngredientValue={specifyIngredientValue}
          setSpecifyIngredientValue={setSpecifyIngredientValue}
          isSupplierExist={isSupplierExist}
        />
      )}
    </>
  );
});

export default SupplerRegister;
