import { useState } from "react";
import { Dimmer, Grid, Loader, Table } from "semantic-ui-react";
import { TbFileSearch } from "react-icons/tb";
import { MdModeEditOutline } from "react-icons/md";
import { CommonTable, TitleBar } from "../../../components";
import SupplierEditModal from "../supplierEdit/SupplierEditModal";
import SupplierDetailsModal from "../supplierDetails/SupplierDetailsModal";
import { Supplier, useGetSuppliers } from "../../../api/supplier";
import PaginationView from "../../../components/pagination/Pagination";

const SupplierListCol = [
  {
    name: "Supplier Name",
  },
  {
    name: "Contact Name",
  },
  {
    name: "Supplier Email",
  },
  {
    name: "Contact Number",
  },
  {
    name: "",
  },
];

const SupplierList = () => {
  const [supplierEdit, setSupplierEdit] = useState<Supplier | null>(null);
  const [supplierDetails, setSupplierDetails] = useState<Supplier | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const {
    data: supplierData,
    isLoading: isLoadingSupplier,
    refetch,
  } = useGetSuppliers({
    pageNumber,
    pageSize,
  });

  const suppliers: Supplier[] = supplierData?.result || [];
  const totalPages = Math.ceil(
    (supplierData?.total || 0) / (supplierData?.pageSize || 0)
  );

  const loadTableData = () => {
    return suppliers.map((supplier: Supplier, index: number) => {
      const copyProducts = [...supplier.products];
      copyProducts.shift();
      return [
        <Table.Row className="tbleR" key={`supplier_${index}`}>
          <Table.Cell>
            <p>
              {supplier["supplier_name"]}{" "}
            </p>
          </Table.Cell>
          <Table.Cell>
            <p>{supplier?.["supplier_contact_name"] || "-"}</p>
          </Table.Cell>
          <Table.Cell>
            <p>{supplier?.["supplier_email"] || "-"}</p>
          </Table.Cell>
          <Table.Cell>
          <p>{supplier?.["supplier_phone"] || "-"}</p>
          </Table.Cell>
          <Table.Cell>
            <Grid centered>
              <Grid.Column computer={4} tablet={16} mobile={16}>
                <TbFileSearch
                  onClick={() => {
                    setSupplierDetails(supplier);
                  }}
                  size={24}
                  color="var(--tableEditIcon)"
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={16} mobile={16}>
                <MdModeEditOutline
                  cursor={"pointer"}
                  onClick={() => setSupplierEdit(supplier)}
                  size={24}
                  color="var(--tableEditIcon)"
                />
              </Grid.Column>
            </Grid>
          </Table.Cell>
        </Table.Row>,
      ];
    });
  };

  // Loading state check
  if (isLoadingSupplier) {
    return (
      <Dimmer active>
        <Loader content="Loading" />
      </Dimmer>
    );
  }

  return (
    <>
      <TitleBar titleName={"Supplier"} />
      <Grid>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <CommonTable tableHeaderData={SupplierListCol}>
            {loadTableData()}
          </CommonTable>
          <PaginationView
            currentPageNo={pageNumber}
            totalPages={totalPages}
            pageSize={pageSize}
            currentSelectPage={(page: number) => {
              setPageNumber(page);
            }}
            changePageSize={(pageSize: number) => {
              setPageSize(pageSize);
            }}
          />
        </Grid.Column>
      </Grid>
      <SupplierEditModal
        visibleModal={!!supplierEdit}
        supplier={supplierEdit}
        closeModal={() => setSupplierEdit(null)}
        onSuccessEdit={() => {
          setSupplierEdit(null);
          refetch();
        }}
      />
      <SupplierDetailsModal
        visibleModal={!!supplierDetails}
        supplier={supplierDetails}
        setVisibleModal={() => setSupplierDetails(null)}
      />
    </>
  );
};

export default SupplierList;
