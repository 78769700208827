export default {
  //common
  mainColor: "#11387F",
  mainTextColor: "#11387F",
  mainTextInputHeader: "#007FC8",
  mainTextInptColor: "#11387F",
  mainTextInputBorderColor: "#93C7C6",
  commonModalHeader: "#0068FF",
  closeIcon: "#11387F",
  //menu bar
  menuBarIconActive: "#0068FF",
  menuBarIconDeactive: "#B0D1FF",
  //title with bottom borader
  titleColor: "#0068FF",
  titleBorderColor: "#0068FF",
  //accordion
  titleActiveColor:"#ffffff",
  titleActiveBackground: "#007FC8",
  //switch view
  switchActiveColor: "#0068FF",
  //table
  tableHeaderColor: "#007FC8",
  tableHeaderText: "#fff",
  tableEditIcon: "#0068FF",
  //button
  buttonColor: "#0068FF",
  //product summary
  pecentageSub: "#B0D1FF",
  //product summary header
  productSummaryTab: "#007FC8",
  activeProductTab: "#11387F",
  cardHeader: "#ffffff",
  nodeCardColor: "#ECE8E5",
  cardIconColor: "#11387F",
  digramSilder: "#ECE8E5",
  drawerSearchView: "#D7D2CB",
  drawingIconsSepia: "100%",
  drawingIconsHueRotate: "150deg",
  //pageTitle
  pageTitleColor: "#0068FF",
  //mapping
  mappingImageBackground: "#B0D1FF",
  mappingIconColor: "#11387F",
  mappingIconTitleText: "#11387F",
  mappingIconTitleSubText: "#11387F",
  mappingTitleViewBackground: "#B0D1FF",
  mappingTitleText: "#11387F",
  mappingArrow: "#B0D1FF",
  mappingBottomTitleText: "#11387F",
  mappingResizeIcon: "#B0D1FF",
  mappingGroupTitle: "#11387F",
  mappingSidePanel: "#fff",
  mappingIconCard: "#fff",
  mappingSearch: "#B0D1FF",
  mappingSideOpenButton: "#B0D1FF",
  mappingCategoryTab: "#B0D1FF",
  mappingBottomButton: "#B0D1FF",
  //mapping modal
  modalCloseIcon: "#11387F",
};
