import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ApiServiceErr, axiosApi } from "./apiService";
import { errorView } from "../helpers/ErrorHandler";
import { toast } from "react-toastify";

export const useGetIngredientList = (pageNumber: number, pageLimit: number) =>
  useQuery(["useGetIngredientList", pageNumber, pageLimit], async () => {
    const response = await axiosApi.get(
      `/ingredient?pageNumber=${pageNumber}&pageSize=${pageLimit}`
    );
    return response.data;
  });

export const useGetIngredientWholeList = (addNew?:any) =>
  useQuery(["getIngredientWholeList"], async () => {    
    const response = await axiosApi.get(`/ingredient/list`);
    const newD = await response?.data?.map((ingred: any) => {
      const newIngredient: any = {
        id: ingred._id,
        title: ingred.ingredient_name,
        ingredientData:ingred
      };
      return newIngredient;
    });
    if(addNew){
      return [ { id: 0, title: "New" },...newD];
    }else{
      return newD;
    }
  });

export const useGetIngredientsWithSupplier = () =>
  useQuery(
    ["getIngredientsWithSupplier"],
    async () => {
      const response = await axiosApi.get(`/ingredient/list`);
      if (response.status === 200) {
        const newD = await response?.data?.map((ingred: any) => {
          const newDSupp = ingred?.suppliers?.map((supp: any, index: any) => {
            return {
              key: `${ingred._id}-${supp._id}`,
              text: `${ingred.ingredient_name}${
                supp?.supplier_name ? `, ${supp?.supplier_name || ""}` : ""
              }`,
              value: {
                ingredient_id: ingred._id,
                ingredient_name: ingred.ingredient_name,
                supplier_id: supp._id,
                supplier_name: supp.supplier_name,
              },
            };
          });
          return newDSupp;
        });

        return [...newD.flat()];
      } else {
        return response.data;
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

export const useGetIngredientsProductList = () =>
  useQuery(
    ["getIngredientProductList"],
    async () => {
      const response = await axiosApi.get(
        "/products?product_status=ACTIVE&tenant_id=null&isAllData=true"
      );

      if (response.status === 200) {
        const newD = await response?.data?.result?.map((product: any) => {
          const newProduct: any = {
            id: product._id,
            title: product.product_name,
            product_type: product.product_type,
            productDetails: {
              product_id: product._id,
              product_name: product.product_name,
              tenant_id: product.tenantId,
            },
          };
          return newProduct;
        });
        return newD;
      } else {
        return response.data;
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

export const useGetIngredientsSupplierList = () =>
  useQuery(
    ["getIngredientSupplierList"],
    async () => {
      const response = await axiosApi.get("/supplier/list");

      if (response.status === 200) {
        const newD = await response?.data?.map((supplier: any) => {          
          const newSupplier: any = {
            id: supplier._id,
            title: supplier?.supplier_name,
            supplierDetails: supplier
          };
          return newSupplier;
        });
        return [{ id: 0, title: "New" },...newD];
      } else {
        return response.data;
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

export const usePostIngredientData = (data?: any) => {
  const queryClient = useQueryClient();

  return useMutation<any, ApiServiceErr, any>(
    async (data) => {
      const response = await axiosApi.post(`ingredient`, data);
      return response.data;
    },
    {
      onSuccess: () => {
        // Invalidate specific queries or query keys after a successful mutation
        queryClient.invalidateQueries(["getIngredientList"]);
      },
    }
  );
};

export const usePutIngredientData = (data?: any) => {
  const queryClient = useQueryClient();
  return useMutation<string, ApiServiceErr, any>(
    async (data) => {
      const response = await axiosApi.put(`ingredient/${data?._id}`, data);
      return response.data;
    },
    {
      onSuccess: () => {
        // Invalidate specific queries or query keys after a successful mutation
        queryClient.invalidateQueries(["getIngredientList"]);
      },
    }
  );
};

export const usePostSupplierData = (data?: any) => {
  const queryClient = useQueryClient();

  return useMutation<string, ApiServiceErr, any>(
    async (data) => {
      const response = await axiosApi.post(`supplier`, data);
      return response.data;
    },
    {
      onSuccess: () => {
        // Invalidate specific queries or query keys after a successful mutation
        queryClient.invalidateQueries(["getIngredientList"]);
      },
    }
  );
};

export const usePutSupplierData = (data?: any) => {
  const queryClient = useQueryClient();

  return useMutation<string, ApiServiceErr, any>(
    async (data) => {
      const response = await axiosApi.put(`supplier/${data?._id}`, data);
      return response.data;
    },
    {
      onSuccess: () => {
        // Invalidate specific queries or query keys after a successful mutation
        queryClient.invalidateQueries(["getIngredientList"]);
      },
    }
  );
};

export const useDeleteIngredientData = (data?: any) => {
  const queryClient = useQueryClient();
  return useMutation<string, ApiServiceErr, any>(
    async (id) => {
      const response = await axiosApi.delete(`ingredient/${id}`);
      return response.data;
    },
    {
      onSuccess: () => {
        // Invalidate specific queries or query keys after a successful mutation
        queryClient.invalidateQueries(["getIngredientList"]);
      },
    }
  );
};

export const useGetProductIngredient = (productId: any) =>
  useQuery(
    ["getProductIngredientList", productId],
    async () => {
      const response = await axiosApi.get(
        `/ingredient/by-product-id/${productId}`
      );
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: !!productId,
      onError: () => {
        errorView("Something went wrong");
      },
    }
  );

export const useGetIngredientPackaging = (data: any) =>
  useQuery(
    ["getIngredientPackaging", data],
    async () => {
      const response = await axiosApi.get(
        `/ingredient/packaging/${data.ingredientId}/${data.supplierId}`
      );
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      enabled: !!data.loadPackageData,
      onError(err: any) {
        if (err.msg === "Please create packaging") {
          toast.warning("Please create packaging");
        } else {
          errorView("Something went wrong");
        }
      },
    }
  );

export const usePostCreateIngredientPackaging = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
      const response = await axiosApi.post(
        `/ingredient/packaging/${data.packagingType}/${data.ingredientId}/${data.supplierId}`, 
        data.data,
        {
          params: {
            packagingId: data?.packagingId
          },
        }
      );
      return response.data;
  });
};


export const usePutUpdateIngredientPackaging = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
      const response = await axiosApi.put(
        `/ingredient/packaging/${data.packagingType}/${data?.packagingId}`, 
        data.data,
        {
          params: {
            ingredientId:  data.ingredientId
          },
        }
      );
      return response.data;
  });
};

export const usePostUpdateIngredientPackagingUnit = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
      const response = await axiosApi.post(
        `/ingredient/packaging/detail`, 
        data.data,
        {
          params: {
            ingredientId:  data.ingredientId,
            supplierId: data.supplierId,
            packagingId:data?.packagingId,
          },
        }
      );
      return response.data;
  });
};

export const useDeleteIngredientPackaging = (data?: any) => {
  return useMutation<string, ApiServiceErr, any>(async (data) => {
      const response = await axiosApi.delete(
        `/ingredient/packaging/${data.packagingType}/${data.subPackagingId}`,
        {
          params: {
            ingredientId:data.ingredientId,
            packagingId:data?.packagingId,
          },
        }
      );
      return response.data;
  });
};