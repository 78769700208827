import { Checkbox, Grid } from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { InputText, InputTextArea } from "../../../components";
import useWindowDimensions from "../../../hooks/windowsSize";
import "./supplierRegisterForm.scss";
import MainBottomButtonView from "../../../components/mainBottomButtonView/MainBottomButtonView";
import { customEmailValidation } from "../../../utils/utils";

const SupplierRegisterForm = ({
  handleSubmit,
  onSubmit,
  register,
  errors,
  closeView = () => {},
  updateSupplier = true,
  setIsSupplierAsUser = () => {},
  isSupplierAsUser = false,
}: any) => {
  const { height, width } = useWindowDimensions();

  return (
    <Grid>
      <Grid.Column computer={16} tablet={16} mobile={16}>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              style={{
                height: height * 0.9 - 180,
                overflowX: "hidden",
              }}
            >
              <Grid>
                <Grid.Column computer={8} tablet={16} mobile={16}>
                  <InputText
                    register={register}
                    errors={errors.supplier_name}
                    labelName={"Supplier Name"}
                    placeholder="Supplier Name"
                    name="supplier_name"
                    required
                    errorMessage="Supplier Name can't be empty!"
                  />
                </Grid.Column>
                <Grid.Column computer={8} tablet={16} mobile={16}>
                  <InputText
                    register={register}
                    errors={errors.supplier_contact_name}
                    labelName={"Supplier Contact Name"}
                    placeholder="Supplier Contact Name"
                    name="supplier_contact_name"
                    required
                    errorMessage="Supplier Name can't be empty!"
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={16}
                  mobile={16}
                  className="paddingRemoveTop"
                >
                  <InputText
                    register={register}
                    errors={errors.supplier_email}
                    labelName={"Email"}
                    placeholder="Email"
                    name="supplier_email"
                    required={true}
                    customInputValidation={true}
                    validateHandle={(value: any) => {
                       return customEmailValidation(value)
                    }}
                    errorMessage="Supplier email is required"
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={16}
                  mobile={16}
                  className="paddingRemoveTop"
                >
                  <InputText
                    register={register}
                    errors={errors.supplier_phone}
                    labelName={"Contact Number"}
                    placeholder="Contact Number"
                    name="supplier_phone"
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={16}
                  mobile={16}
                  className="paddingRemoveTop"
                >
                  <InputText
                    register={register}
                    errors={errors.supplier_address}
                    labelName={"Address"}
                    placeholder="Address"
                    name="supplier_address"
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={16}
                  mobile={16}
                  className="paddingRemoveTop"
                >
                  <InputText
                    register={register}
                    errors={errors.supplier_province}
                    labelName={"Province"}
                    placeholder="Province"
                    name="supplier_province"
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={16}
                  mobile={16}
                  className="paddingRemoveTop"
                >
                  <InputText
                    register={register}
                    errors={errors.supplier_country}
                    labelName={"Country"}
                    placeholder="Country"
                    name="supplier_country"
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={16}
                  mobile={16}
                  className="paddingRemoveTop"
                >
                  <InputText
                    register={register}
                    errors={errors.supplier_postal_code}
                    labelName={"Zip Code"}
                    placeholder="Zip Code"
                    name="supplier_postal_code"
                  />
                </Grid.Column>
                <Grid.Column
                  computer={16}
                  tablet={16}
                  mobile={16}
                  className="paddingRemoveTop paddingRemoveBottom"
                >
                  <InputTextArea
                    register={register}
                    errors={errors.supplier_other_info}
                    labelName={"Other Information"}
                    placeholder="Other Information"
                    name="supplier_other_info"
                    customInputValidation={true}
                  />
                </Grid.Column>
                <Grid.Column
                  computer={8}
                  tablet={16}
                  mobile={16}
                  className="customCheckpointSupplierUser"
                >
                  <Checkbox
                    label={"Register supplier as user"}
                    checked={isSupplierAsUser}
                    onChange={(e, data) => {
                      setIsSupplierAsUser(!isSupplierAsUser);
                    }}
                  />
                </Grid.Column>
              </Grid>
            </div>

            <MainBottomButtonView
              saveButtonStatus={true}
              saveTitle={updateSupplier ? "Update Supplier" : "Create Supplier"}
              type="submit"
            />
          </form>
        </div>
      </Grid.Column>
    </Grid>
  );
};

export default SupplierRegisterForm;
