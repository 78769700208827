import * as React from "react";
import { getPathName } from "../../utils/utils";

const IngredientMapping = (props: any) => {
  const pathName = getPathName();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={36}
      height={36}
      fill="none"
      {...props}
    >
      <g
        fill={
          pathName == ""
            ? "var(--menuBarIconActive)"
            : "var(--menuBarIconDeactive)"
        }
        clipPath="url(#a)"
      >
        <path
          stroke={
            pathName == ""
              ? "var(--menuBarIconActive)"
              : "var(--menuBarIconDeactive)"
          }
          strokeWidth={1.563}
          d="M34.373 1.626a2.884 2.884 0 0 1 0 4.082l.552.552-.552-.552-4.355 4.354-.229.23v24.39c0 .295-.24.535-.536.535H1.317a.536.536 0 0 1-.536-.535V6.745c0-.296.24-.535.536-.535H25.708l.228-.23 4.355-4.354a2.89 2.89 0 0 1 4.082 0Zm-6.437 32.52h.782V11.363l-1.334 1.333-6.607 6.607-.008.008-.007.008c-.032.034-.041.044-.052.052l-.03.018a8.13 8.13 0 0 1-.657.32c-.343.155-.822.369-1.51.675-.904.402-2.17.965-3.96 1.77a.537.537 0 0 1-.709-.708c.673-1.495 1.18-2.63 1.566-3.492.461-1.03.75-1.675.94-2.089.176-.381.25-.525.286-.586l.042-.04.006-.006.007-.007 6.611-6.611 1.334-1.334H1.853v26.864h26.083ZM19.82 17.64l.553.552.552-.552L33.614 4.95a1.814 1.814 0 1 0-2.565-2.566l-12.691 12.69-.553.553.553.553 1.461 1.46Z"
        />
        <path d="M24.347 25.266v5.625h-.506v-5.625h.506Zm-.956-.957v.507h-5.625v-.507h5.625Zm0 7.032v.506h-5.625v-.506h5.625Zm-1.505-4.318v2.11c0 .14-.113.253-.253.253h-2.11a.253.253 0 0 1-.253-.253v-2.11c0-.14.114-.253.254-.253h2.109c.14 0 .253.114.253.253Zm-.506 1.857v-1.603h-1.603v1.603h1.603Zm-4.064-3.614v5.625h-.506v-5.625h.506Zm-1.66 2.559v.506h-2.812v-.506h2.812Zm-3.965-15.216v5.625h-.506V12.61h.506Zm0 12.657v5.625h-.506v-5.625h.506Zm-.956-13.613v.506H5.11v-.507l5.625.001Zm0 7.031v.507H5.11v-.507h5.625Zm0 5.625v.507H5.11v-.507h5.625Zm0 7.032v.506H5.11v-.506h5.625ZM9.23 14.367v2.11c0 .14-.113.253-.253.253h-2.11a.253.253 0 0 1-.253-.253v-2.11c0-.14.113-.253.253-.253h2.11c.14 0 .253.113.253.253Zm-.506 1.857V14.62H7.12v1.603h1.603Zm.506 10.8v2.109c0 .14-.113.253-.253.253h-2.11a.253.253 0 0 1-.253-.253v-2.11c0-.14.113-.253.253-.253h2.11c.14 0 .253.114.253.253Zm-.506 1.856v-1.603H7.12v1.603h1.603Zm-.549-8.536v2.812H7.67v-2.812h.506ZM4.66 12.609v5.625h-.507V12.61h.507Zm0 12.657v5.625h-.507v-5.625h.507Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill={
              pathName == ""
                ? "var(--menuBarIconActive)"
                : "var(--menuBarIconDeactive)"
            }
            d="M0 0h36v36H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default IngredientMapping;
